import * as React from 'react';
import { navigate } from 'gatsby';

import Layout from '../components/Layout';

export default function Contact({ location }) {
  return (
    <Layout>
      <h1>Contact</h1>
      <form name="contact" method="post" netlify-honeypot="bot-field" data-netlify="true">
        <label htmlFor="full-name">Your Name</label>
        <input type="text" id="full-name" name="full-name" required />
        <label htmlFor="address">Your Address</label>
        <input type="text" id="address" name="address" required />
        <label htmlFor="phone-number">Your Phone Number</label>
        <input type="text" id="phone-number" name="phone-number" required />
        <label htmlFor="email">Your Email</label>
        <input type="text" id="email" name="email" required />
        <label htmlFor="description">Please leave a brief description of your project</label>
        <textarea id="description" name="description" rows="10" required />
        <button type="submit">Submit</button>
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="contact" />
      </form>
    </Layout>
  );
}
